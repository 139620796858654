/* eslint-disable no-unused-vars */
/* eslint-disable require-jsdoc */
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';
import { List, Spin } from 'antd';
import { TOP_RESULTS_LEADERBOARD_API } from 'Src/alumniGiving/endpoints';
import ThemeXModal from 'Src/common/components/themeXModal';
import InfiniteScroll from 'react-infinite-scroller';
import axiosInstance from 'Src/common/utilities/axios_util';
import { SettingsConsumer } from 'Src/alumniGiving/context/settings';
import { commaSeparatedAmountAndCurrency, formatter } from 'Src/common/utilities/data_util';
import { TYPE_GIFT_VISIBILITY_SHOW_GIFT_WITH_AMOUNT } from 'Src/alumniGiving/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faSeedling } from '@fortawesome/pro-light-svg-icons';
import { func, shape } from 'prop-types';
import './giftModal.scss';

function GiftModal({ leaderboard, item, closeModal }) {
  const [giftData, setGiftData] = useState({ results: [] });
  const [settings] = SettingsConsumer();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [nextPageNumber, setNextPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 10;

  function fetchContributors() {
    const queryParams = `dimension=${encodeURIComponent(item.label)}&&dimension_type=${
      item.groupable ? 'group' : 'item'
    }&&page=${nextPageNumber}&&page_size=${pageSize}`;
    return axiosInstance({
      method: 'get',
      url: TOP_RESULTS_LEADERBOARD_API.replace('{}', settings.page.id)
        .replace('<lb_id>', leaderboard.id)
        .concat(`?${queryParams}`),
    });
  }

  useEffect(() => {
    fetchContributors()
      .then((response) => {
        setIsLoading(false);
        setGiftData(response.data);
        setNextPageNumber(2);
        setHasMore(response.data.results.length < response.data.count_gifts);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  function handleInfiniteOnLoad() {
    if (hasMore) {
      setIsLoadingMore(true);
      fetchContributors()
        .then((response) => {
          const updatedGiftData = {
            count_gifts: response.data.count_gifts,
            total_amount: response.data.total_amount,
            results: giftData.results.concat(response.data.results),
          };
          setGiftData(updatedGiftData);
          setHasMore(updatedGiftData.results.length < response.data.count_gifts);
          setNextPageNumber(nextPageNumber + 1);
          setIsLoadingMore(false);
        })
        .catch(() => {
          setIsLoadingMore(false);
        });
    }
  }

  return (
    <ThemeXModal
      modalTitle={`Gifts from ${item.label}`}
      visible
      wrapClassName="gift-contributors-modal"
      handleCancel={closeModal}>
      <>
        {isLoading && (
          <div className="empty-container">
            <Spin />
          </div>
        )}
        {!isLoading && (
          <>
            <div className="gift-contributor-header">
              <span className="arc-p arc-color-B55 mr24">
                <FontAwesomeIcon icon={faGift} className="mr8" />
                {giftData.count_gifts}
              </span>
              <If condition={leaderboard?.gift_visibility === TYPE_GIFT_VISIBILITY_SHOW_GIFT_WITH_AMOUNT}>
                <span className="arc-p arc-color-B55">
                  <FontAwesomeIcon icon={faSeedling} className="mr8 arc-color-primary" />
                  {formatter(settings.page.currency_display).format(giftData.total_amount)}
                </span>
              </If>
            </div>
            <div className="gift-contributors">
              <InfiniteScroll
                initialLoad={false}
                loadMore={handleInfiniteOnLoad}
                hasMore={!isLoadingMore && hasMore}
                useWindow={false}
                threshold={5}>
                <List
                  dataSource={giftData.results}
                  loading={isLoadingMore}
                  renderItem={(d) => (
                    <div className="donor-row">
                      <p className="arc-H150">{d.donor_name}</p>
                      <Choose>
                        <When
                          condition={
                            d.amount && leaderboard?.gift_visibility === TYPE_GIFT_VISIBILITY_SHOW_GIFT_WITH_AMOUNT
                          }>
                          <p className="arc-H150">
                            {commaSeparatedAmountAndCurrency([{ total: d.amount, currency: d.currency }])}
                          </p>
                        </When>
                        <Otherwise>
                          <p className="arc-H150" />
                        </Otherwise>
                      </Choose>
                    </div>
                  )} />
              </InfiniteScroll>
            </div>
          </>
        )}
      </>
    </ThemeXModal>
  );
}

GiftModal.propTypes = {
  leaderboard: shape().isRequired,
  item: shape().isRequired,
  closeModal: func.isRequired,
};

export default GiftModal;
