/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState } from 'react';
import { string, func, bool, oneOfType, object, shape } from 'prop-types';
import { Share } from 'arcl';
import { Button, Modal, message } from 'antd';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/pro-solid-svg-icons';
import UGC from 'Src/common/components/UGC';
import WaitModal from '../waitModal';
import { copyToClipboard } from '../../utilities/dom_utils';
import I18nCustomFormatter from '../i18nCustomFormatter/index';
import './style.scss';

function ThemeXShareModalGiving({
  visible,
  url,
  title,
  description,
  handleClose,
  dashboardUrl,
  dashboardAltMsg,
  handleFileChange,
  update,
  ugc,
  fileList,
  actionUrl,
  method,
  defaultImage,
  donorName,
  secretHash,
}) {
  const [isWaitModalOpen, setIsWaitModalOpen] = useState(false);
  const [shareUrl] = useState('');

  function copyLink() {
    copyToClipboard(getSharableUrl());
    message.success('Copied link to clipboard');
  }

  function getSharableUrl(socialType) {
    if (!url) {
      return '';
    }
    let shareUrlL = new URL(url);

    switch (socialType) {
      case 'fb':
        shareUrlL += encodeURIComponent('&utm_source=ugc');
        if (!isEmpty(ugc)) {
          // please do not remove encodeURIComponent as facebook does not like it
          // Facebook needs fully encoded component instead of only value
          shareUrlL += encodeURIComponent(`&ugc=${ugc.id}`);
          shareUrlL += encodeURIComponent(`&timestamp=${new Date().getTime()}`);
        }
        shareUrlL += encodeURIComponent('&utm_medium=facebook');
        break;
      case 'twitter':
        shareUrlL += `&utm_source=${encodeURIComponent('ugc')}`;
        if (!isEmpty(ugc)) {
          // Twitter does not work when query param and value both are encoded
          shareUrlL += `&ugc=${encodeURIComponent(ugc.id)}`;
          shareUrlL += `&timestamp=${encodeURIComponent(new Date().getTime())}`;
        }
        shareUrlL += `&utm_medium=${encodeURIComponent('twitter')}`;
        break;
      default:
        shareUrlL += encodeURIComponent('&utm_medium=ugc');
    }
    return shareUrlL;
  }

  function toggleWaitModal() {
    setIsWaitModalOpen(!isWaitModalOpen);
  }

  return (
    <Fragment>
      <Modal
        visible={visible}
        className="themex-share-modal"
        width="25rem"
        closable
        maskClosable={false}
        keyboard={false}
        footer={null}
        onCancel={() => handleClose()}>
        <div className="themex-share-modal-header">
          <p className="arc-H300 title">{title}</p>
          <p className="arc-p arc-color-B45">{description}</p>
        </div>
        <div className="mt12 mb16">
          <UGC
            update={update}
            handleFileChange={handleFileChange}
            ugc={ugc}
            fileList={fileList}
            actionUrl={actionUrl}
            method={method}
            defaultImage={defaultImage}
            donorName={donorName}
            secretHash={secretHash} />
        </div>
        <div>
          <Share
            title={window?.pageData?.name || 'Giving page'}
            url={getSharableUrl()}
            style={{ width: '100%', padding: '8px 0' }}
            trigger={['click']}
            disableNativeShare>
            <div>
              <FontAwesomeIcon className="mr8" icon={faShare} />
              Share On
            </div>
          </Share>
        </div>
        <div className="themex-share-links">
          <p className="share-description mt16 src-p arc-color-B45">
            <I18nCustomFormatter id="share-modal-footer-message-prefix" />
            <br />
            <br />
            Every share you make has a
            {' '}
            <a className="share-description-link" onClick={() => copyLink()}>
              unique invite
            </a>
            {' '}
            link that is yours. You can track the impact this tribute has made to the campaign by visiting your
            dashboard.
          </p>
          <If condition={dashboardUrl || dashboardAltMsg}>
            <Choose>
              <When condition={dashboardUrl}>
                <Button type="link" shape="round" href={dashboardUrl} className="dashboard-link mt16" target="_blank">
                  <I18nCustomFormatter id="view-my-dashboard-btn-label" />
                </Button>
              </When>
              <Otherwise>
                <div className="alt-block">{dashboardAltMsg}</div>
              </Otherwise>
            </Choose>
          </If>
        </div>
        <Button onClick={() => handleClose()} className="close-action arc-btn-subtle mt16 arc-p arc-color-B20">
          <I18nCustomFormatter id="back-to-campaign-link-label" />
        </Button>
      </Modal>
      {isWaitModalOpen && <WaitModal url={shareUrl} closeModal={toggleWaitModal} />}
    </Fragment>
  );
}

ThemeXShareModalGiving.propTypes = {
  visible: bool.isRequired,
  title: oneOfType([string, object]),
  description: oneOfType([string, object]),
  url: string,
  handleClose: func.isRequired,
  handleFileChange: func,
  fileList: shape(),
  ugc: shape(),
};

ThemeXShareModalGiving.defaultProps = {
  title: 'Invite a friend',
  description: '',
  url: `${window.location.origin}${window.location.pathname}`,
  fileList: [],
  ugc: {},
  handleFileChange: () => {},
};

export default ThemeXShareModalGiving;
