import React from 'react';
import { string, bool, shape } from 'prop-types';
import HeroBanner from '../heroBanner';
import ThemeXHeader from '../themeXHeader';
import './style.scss';

function ThemeXContainer({
  showHeader,
  userData,
  showHeroBanner,
  isLoaded,
  heroBannerImage,
  heroLogo,
  heroTitle,
  showHeroBackLink,
  heroBackText,
  redirectLink,
  heroBannerChildren,
  isDefaultCoverImage,
  children,
  isImageResizingEnabled,
  showLoginButton,
  title,
  showShare,
  shareUrl,
  disableLogoRedirect,
}) {
  return (
    <div id="themex-wrapper">
      <div id="themex-container" className={showHeader ? 'with-header-container' : ''}>
        <If condition={showHeader}>
          <ThemeXHeader
            userData={userData}
            showLoginButton={showLoginButton}
            title={title}
            showShare={showShare}
            shareUrl={shareUrl}
            disableLogoRedirect={disableLogoRedirect}
          />
        </If>
        <main>
          <If condition={showHeroBanner}>
            <HeroBanner
              isImageResizingEnabled={isImageResizingEnabled}
              isLoaded={isLoaded}
              logo={heroLogo}
              bannerImage={heroBannerImage}
              title={heroTitle}
              showBackLink={showHeroBackLink}
              backText={heroBackText}
              redirectLink={redirectLink}
              isDefaultCoverImage={isDefaultCoverImage}>
              {heroBannerChildren}
            </HeroBanner>
          </If>
          <div id="themex-body">{children}</div>
        </main>
      </div>
    </div>
  );
}

ThemeXContainer.propTypes = {
  showHeroBanner: bool.isRequired,
  isLoaded: bool,
  heroBannerImage: string.isRequired,
  heroLogo: string,
  heroTitle: string.isRequired,
  heroBackText: string.isRequired,
  redirectLink: string,
  showHeroBackLink: bool.isRequired,
  showHeader: bool,
  userData: shape().isRequired,
  isDefaultCoverImage: bool,
  isImageResizingEnabled: bool,
  title: string,
  showShare: bool,
  shareUrl: string,
};

ThemeXContainer.defaultProps = {
  isLoaded: false,
  redirectLink: null,
  showHeader: false,
  isDefaultCoverImage: false,
  heroLogo: null,
  isImageResizingEnabled: false,
  title: '',
  showShare: false,
  shareUrl: '',
};
export default ThemeXContainer;
