import { findIndex } from 'lodash';
import { BREAKPOINT, ORIENTATION, IMAGE_TYPE_COVER, IMAGE_TYPE_LOGO } from 'Src/common/constants';
import { PAGE_TYPE_COMPETITIVE_FUNDRAISING, PAGE_TYPE_CROWDFUNDING } from 'Src/alumniGiving/constants';

export const isFieldVisible = (field) => {
  const index = findIndex(window.giftStructure, (structure) => structure.field === field);
  if (index > -1) {
    return window.giftStructure[index].is_visible;
  }
  return false;
};

export const isFieldRequired = (field) => {
  const index = findIndex(window.giftStructure, (structure) => structure.field === field);
  if (index > -1) {
    return window.giftStructure[index].is_required;
  }
  return false;
};

export const getFeaturedImage = () => {
  const width = document.documentElement.clientWidth || 0;
  const orientation = width < BREAKPOINT ? ORIENTATION.PORTRAIT : ORIENTATION.LANDSCAPE;
  const featuredImage = window.pageData.images.filter(
    (image) => image.image_type === IMAGE_TYPE_COVER && image.image_orientation === orientation,
  );
  const pageImages = window.pageData.images.filter((image) => image.image_type !== IMAGE_TYPE_LOGO);
  if (featuredImage.length) {
    return featuredImage[0].image;
  }
  if (pageImages.length) {
    return pageImages[0].image;
  }
  if (window.pageData.type === PAGE_TYPE_CROWDFUNDING) {
    return window.defaultCrowdfundingBannerImage;
  }
  if (window.pageData.type === PAGE_TYPE_COMPETITIVE_FUNDRAISING) {
    return window.defaultCrowdfundingBannerImage;
  }
  return window.defaultCheckoutBannerImage;
};
