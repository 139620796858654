import React, { useState } from 'react';
import { Avatar, Col, Row, Dropdown, Menu, Button } from 'antd';
import { Share } from 'arcl';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { faShare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ThemeXSignInModal from '../themeXSignInModal';
import './style.scss';

function ThemeXHeader({ userData, showLoginButton = true, title, showShare, shareUrl, disableLogoRedirect = false }) {
  const [showLoginModal, toggleLoginModal] = useState(false);

  const userMenu = (
    <Menu>
      <Menu.Item>
        <a href={userData.profile_url}>My Profile</a>
      </Menu.Item>
      <Menu.Item>
        <a href="/logout">Logout</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <header>
      <Row className="themex-header" type="flex" align="middle" justify="space-between">
        <Col>
          <a href="/" className="arc-focus-outline" disabled={disableLogoRedirect}>
            <img src={window.customerLogo} alt={`${window.collegeName} logo`} className="header-logo" />
            <If condition={title}>
              <span className="arc-p arc-font-weight-medium ml8">{title}</span>
            </If>
          </a>
        </Col>
        <Col>
          <Choose>
            <When condition={showShare}>
              <Share title="Share" url={shareUrl} disableNativeShare>
                <div className="share-card-icon-text">
                  <span id="share-trigger" className="arc-anchor">
                    <span>Share</span>
                  </span>
                  <FontAwesomeIcon icon={faShare} className="ml12 arc-color-primary" />
                </div>
              </Share>
            </When>
            <Otherwise>
              <Choose>
                <When condition={userData}>
                  <Dropdown overlay={userMenu} placement="bottomRight" trigger={['click']}>
                    <Button type="link" className="link-btn arc-focus-outline">
                      <Avatar className="mr8" alt="Profile Picture" src={userData.present_picture} />
                      <FontAwesomeIcon icon={faChevronDown} />
                    </Button>
                  </Dropdown>
                </When>
                <Otherwise>
                  <If condition={showLoginButton}>
                    <Button
                      type="primary"
                      className="arc-btn-subtle arc-focus-outline"
                      id="login-button"
                      onClick={() => toggleLoginModal(true)}>
                      Login
                    </Button>
                  </If>
                </Otherwise>
              </Choose>
            </Otherwise>
          </Choose>
        </Col>
      </Row>
      <ThemeXSignInModal visible={showLoginModal} handleClose={() => toggleLoginModal(false)} />
    </header>
  );
}

export default ThemeXHeader;
