import React, { useEffect, useState } from 'react';
import { Input, Row, Col, Tooltip } from 'antd';
import ImageWithFallbackSrc from 'Src/common/components/imageWithFallbackSrc';
import { getImagePath, getRandomCoverImage } from 'Src/common/utilities/dom_utils';
import { find } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { countDecimals, toFixed } from 'Src/common/utilities/data_util';
import DivButton from 'Src/common/components/controller/buttons/buttonController';
import CurrencyRenderer from '../../../../common/components/currencyRenderer';
import { GiftConsumer } from '../../../context/gift';
import { SettingsConsumer } from '../../../context/settings';
import { success } from '../../../actionCreators';
import { MODULE_GIFT } from '../../../constants';

function FundCard({ name, description, cover_photo: coverPhoto, quickSlabs, id, isLocked, tabIndex }) {
  const [gift, giftDispatch] = GiftConsumer();
  const [settings] = SettingsConsumer();

  const [fundAmount, setFundAmount] = useState();

  useEffect(() => {
    const currentFund = find(gift.funds, ({ fund }) => fund.id === id);
    if (currentFund) {
      setFundAmount(currentFund.fund.amount || undefined);
    } else {
      setFundAmount();
    }
  }, [gift]);

  const checkAmount = (amount) => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(parseFloat(amount))) {
      return undefined;
    }
    if (countDecimals(amount) > 2) return toFixed(parseFloat(amount), 2);
    return amount;
  };

  const updateFundAmount = (amount, e) => {
    if (isLocked) return;
    if (parseFloat(amount) < 0) return;

    // NOTE: To be fixed later for firefox (to prevent entering non-numeric values)
    // if (e && !amount.match(/^[0-9]+$/)) {
    //   return;
    // }

    for (let i = 0; i < gift.funds.length; i++) {
      // If multiple attribution is not enabled, only allow to select single amount
      if (!settings.page.is_multiple_funds_attribution_enabled && gift.funds[i].fund.id !== id) {
        gift.funds[i].fund.amount = 0;
        gift.funds[i].fund.is_open = false;
      }
      if (gift.funds[i].fund.id === id) {
        gift.funds[i].fund.amount = checkAmount(amount);
        gift.funds[i].fund.is_open = false;
      }
    }
    setFundAmount(checkAmount(amount));
    giftDispatch(success(MODULE_GIFT, gift));
  };

  return (
    <div className="fund-card-wrapper" key={id}>
      <div className="fund-card-detail">
        <Choose>
          <When condition={coverPhoto}>
            <ImageWithFallbackSrc
              alt="fund-image"
              className="fund-img"
              src={getImagePath(coverPhoto, 'thumbnail')}
              fallbackSrc={coverPhoto}
            />
          </When>
          <Otherwise>
            <ImageWithFallbackSrc
              alt="fund-image"
              className="fund-img"
              src={`${window.staticPath}img/givingx/no-image-placeholder.svg`}
              fallbackSrc={getRandomCoverImage()}
            />
          </Otherwise>
        </Choose>
        <div className="fund-card-text-info">
          <div className="fund-card-title arc-color-B65 arc-H150 arc-font-weight-Medium ">{name || ''}</div>
          <div className="fund-card-description arc-p arc-color-B65">{description || ''}</div>
        </div>
      </div>
      <div className="fund-input">
        <Input
          id={`${name}-input`}
          aria-label={`Enter amount for fund: ${name}`}
          onWheel={(e) => e.target.blur()}
          type="number"
          readOnly={isLocked}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(isLocked && {
            suffix: (
              <Tooltip
                title={
                  <p className="arc-support">
                    You cannot edit this amount. It has been locked by the administrator. For any assistance, please
                    contact {window.collegeEmail}.
                  </p>
                }>
                <FontAwesomeIcon icon={faLock} className="arc-color-B25" />
              </Tooltip>
            ),
          })}
          tabIndex={tabIndex}
          allowClear
          value={fundAmount}
          onChange={(e) => updateFundAmount(e.target.value, e)}
          prefix={<CurrencyRenderer currencyId={gift.account.currency} />}
          placeholder="Enter Amount"
        />
      </div>
      <If condition={!isLocked && settings.page.are_quick_select_options_visible}>
        <Row gutter={[6, 8]} className="fund-select">
          {quickSlabs.map(
            (slab, idx) =>
              idx <= 5 && (
                <Col key={slab} span={8}>
                  <DivButton
                    onClick={() => updateFundAmount(slab)}
                    className={`fund-select-item arc-H100 arc-font-weight-bold arc-cursor-p ${
                      parseFloat(fundAmount) === parseFloat(slab) && 'selected'
                    }`}>
                    {' '}
                    <CurrencyRenderer currencyId={gift.account.currency} />
                    {slab}
                  </DivButton>
                </Col>
              ),
          )}
        </Row>
      </If>
    </div>
  );
}

export default FundCard;
